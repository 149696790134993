import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store: any = createStore({
  state() {
    return {
      user: {},
      packageVersion: process.env.VUE_APP_VERSION || '0'
    };
  },
  mutations: {
    set: (state, payload) => {
      state.user = payload
    },
  },
  actions: {
    set: ({
      commit
    }, payload) => {
      commit('set', payload)
    },
  },
  getters: {
    user: state => state.user,
    guest: state => Object.keys(state.user).length === 0,
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
