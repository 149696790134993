
import { IonApp, IonRouterOutlet, isPlatform, getPlatforms } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  created() {
    if (!isPlatform("pwa")) {
      this.$router.push("/info");
    }
    console.log(getPlatforms());
  },
});
