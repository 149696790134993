<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button :default-href="pageBackLink"></ion-back-button>
        </ion-buttons>
        <!-- <ion-title>{{ pageTitle }}</ion-title> -->
        <ion-title size="small"
          ><b>{{ pageTitle }}</b
          ><br />{{ pageSubTitle }}</ion-title
        >
        <ion-buttons slot="end">
          <slot name="actions-end"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :class="pageBackground">
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonBackButton,
  IonButtons,
} from "@ionic/vue";

export default {
  props: ["pageTitle", "pageSubTitle", "pageBackLink", "pageBackground"],
  components: {
    IonPage,
    IonHeader,
    IonTitle,
    IonContent,
    IonToolbar,
    IonBackButton,
    IonButtons,
  },
};
</script>