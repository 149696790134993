import { createRouter, createMemoryHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/hal/beranda'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/info',
    component: () => import('@/views/InfoPage.vue')
  },
  {
    path: '/info/panduan-android',
    component: () => import('@/views/BerandaPanduanAndroidPage.vue'),
  },
  {
    path: '/info/panduan-ios',
    component: () => import('@/views/BerandaPanduanIosPage.vue'),
  },
  {
    path: '/kebijakan-privasi',
    component: () => import('@/views/KebijakanPrivasiPage.vue')
  },
  {
    path: '/hal/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/hal/beranda'
      },
      {
        path: 'beranda',
        component: () => import('@/views/BerandaPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'rekapan',
        component: () => import('@/views/RekapanPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'profil',
        component: () => import('@/views/ProfilPage.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: 'profil/shift-kerja',
        component: () => import('@/views/ProfilShiftKerjaPage.vue'),
        meta: {
          auth: true
        }
      }
    ]
  },
  {
    path: '/beranda/presensi/:id/:status',
    component: () => import('@/views/BerandaPresensiPage.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/profil/libur-khusus',
    component: () => import('@/views/ProfilLiburKhususPage.vue'),
    meta: {
      auth: true
    }
  }
];

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

// tambahkan ini untuk melakukan pengecekan pada setiap routing
router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some(record => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters['guest']) {
      console.log("Login dlu")
      // tampilkan pesan bahwa harus login dulu
      // store.dispatch('alert/set', {
      //   status: true,
      //   text: 'Login first',
      //   color: 'error',
      // })
      // // tampilkan form login
      // store.dispatch('dialog/setComponent', 'login')
      router.replace("/login");
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
